import {useEffect} from 'react'
import './Fact.css'
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the AOS styles

const Fact = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000, // Set the duration of the animation (in ms)
          once: true,     // Whether the animation should happen only once - while scrolling down
          easing: 'ease-in-out',
        });
      }, []);

  return (
    <div className='fact'>
        <div className="fact-label" data-aos="fade-right">
            <div className="fact-heading">Did you know ?</div>
            <div className="fact-subheading">In fact...</div>
        </div>

        <div className="fact-main" data-aos="fade-up">
            <ul>
                <li>The present day driveway was the first route to the quarry and continued behind the Fathers House towards “Four Poles”. The truckers saw reason one day and decided not to continue using the route – especially when they noticed that the road had been blasted out of existence!</li>
                <li>Loyola owes its huge flat playground to the hard work put in by the men and bulldozers of the Bombay Engineering Group (BEG). Fr. Schoch had requested help from the Sappers for this monumental task.</li>
                <li>The first two teachers at Loyola were Mr. S Swamy, and Mr. V. Mahamuni. Swamy Sir claims to be senior by 5 minutes, as Mahamuni Sir was late for the assembly on that first day. Over to you, Mahamuni Sir!</li>
                <li>In the initial years, different classes shared classrooms! Education and building construction went on side by side, so it was not uncommon to have Maths and English taught to students from different classes, but sitting in the same class…</li>
                <li>(Retd.) Major Menezes donated the Riklin cup (named after Fr. Maximilian Riklin S.J., a former principal of St. Vincent’s High School). He was in charge of the Civil Department of the Society of Jesus.</li>
            </ul>
        </div>
        <hr className='fact-hr'/>
    </div>
  )
}

export default Fact
