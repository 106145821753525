import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import Home2 from './Components/Home2';
import LandingPage from './Components/LandingPage';
import CoCurricular from './Components/CoCurricular';
import Case from './Components/Case';
import Articles from './Components/Articles';
import Contact from './Components/Contact';
import About from './Components/About';
import Message from './Components/Message';
import General from './Components/General';
import Admission from './Components/Admission';


function App() {

  return (
    <Router>
      <div className="App">
        <LandingPage /> {/* Navbar is displayed on all pages */}
        <Routes>
          {/* Home route with both LandingPage and Home2 components */}
          <Route path="/" element={<><Home2 /></>} />
          {/* CoCurricular component route */}
          <Route path="/cocurricular" element={<CoCurricular />} />
          <Route path="/today" element={<Case/>} />
          <Route path="/today/articles" element={<Articles/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/about/message" element={<Message/>} />
          <Route path="/general" element={<General/>} />
          <Route path="/admission" element={<Admission/>} />
        </Routes>
        <Footer /> {/* Footer is displayed on all pages */}
      </div>
    </Router>
  );
}

export default App;
