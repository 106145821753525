import React, {useEffect} from 'react'
import './Admission.css'
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the AOS styles
import { Helmet } from 'react-helmet';

const Admission = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000, // Set the duration of the animation (in ms)
          once: true,     // Whether the animation should happen only once - while scrolling down
          easing: 'ease-in-out',
        });
      }, []);


  return (
    <>
    <Helmet>
      <title>Admissions - Loyola</title>
      <meta name="Loyola - Admissions in Loyola" description="Admissions for Loyola High School & Junior College"/>
      <link rel="canonical" href="/admission" />
    </Helmet>
    <div className='admission' id='admission'>
        <div className="admission-label" data-aos="fade-right">
            <div className="label-main">STD. XI ADMISSION 2023-24</div>
            <div className="label-subtext">Let's Talk</div>
            <div className="admission-nav">
            Loyola Junior College is a Christian Minority Institute attached to Secondary School.  Loyola Junior College is Co-Ed and has only a Science faculty.  It has two divisions with a capacity of 160 students.  Being a Minority Institute, 50% of the seats are reserved for Christian students and 10% for In-House (Loyola students).  The remaining seats are open for the general category students.  Loyola strictly follows the Centralised Admission Process of the School Education Department.
            </div>
        </div>

        <div className="admission-text" data-aos="fade-up">
            <div className="highlight">SUBJECTS OFFERED</div>
            <div className="highlight">COMPULSORY</div>
            <div className="para">English</div>
            <div className="para">German</div>
            <div className="para">Math</div>
            <div className="para">Physics</div>
            <div className="para">Chemistry</div>
            <div className="highlight">OPTIONAL:</div>
            <div className="para">Biology / Geology</div>
            <div className='para'>55 seats each allotted for Biology and Geology on merit.</div>
            <div className="highlight">BIFOCAL SUBJECT</div>
            <div className="para">We offer 50 seats of Computer Science ( I & II ) as a Bifocal subject.  It’s an option for German and Optional subjects (Bio/Geo).</div>
            <div className="highlight">SYLLABUS: MAHARASHTRA STATE BOARD</div>
            <div className="highlight">TIMINGS OF JR. COLLEGE: 7.10 a.m. to 1.15 p.m. (Monday to Friday – Inclusive of practical)</div>
            <div className="highlight">ATTENDANCE: Compulsory 80%</div>
            <div className="para">For centralized Admission Process kindly follow the official site <span className='admission-span'><a href="https://pune.11thadmission.org.in/">pune.11thadmission.org.in</a></span> for timely instructions, last year’s cut off, and timetable.</div>
        </div>
    </div>
    </>
  )
}

export default Admission
