import React from 'react'
import './Articles.css'
import { Helmet } from 'react-helmet'

const Articles = () => {
  return (
    <>
    <Helmet>
      <title>Articles - Loyola</title>
      <meta name="Loyola - Articles" description="This is the expression of thoughts by our students"/>
      <link rel="canonical" href="/today/articles" />
    </Helmet>
    <div className='articles' id="articles">
      <div className="articles-label">
            <div className="articles-label-main">Articles</div>
            <div className="articles-label-subtext">An expression of ideas</div>
            <div className="articles-nav">
            The Boys who excel in academics, who overcome odds to wrest inter-school championships, and boys who still remember to write an ode to their mothers. Glimpses of this year’s academic and sports results cement our firm belief in the value of a holistic comprehensive education.
            </div>
        </div>

        <div className="articles-card-container">
    <div className="articles-card">
        <div className="articles-card-header">
            <h2 className="articles-card-heading">An investment in knowledge pays the best interest  <span className='articles-span'>-Benjamin Franklin</span></h2>
        </div>
        <div className="articles-card-body">
            <p className="articles-card-main-text">
            Education requires costly resources, like time and money and those who invest these resources, often end up richer – not just because they often improve their financial situation, but their minds are richer too.

Education has been much of a hassle in India, and the biggest reason for that is poverty. People are poor because of overpopulation. In our country there are more people than jobs. In fact, in the world, many youths who have graduated are forced to do jobs which don’t require graduation. Automation is also taking over jobs. Illiteracy has become a problem. In rural villages, money lenders cheat illiterates into signing papers to get a lot of money from them. So, people don’t earn enough money to educate their family. Some families only educate the boys due to poverty and gender discrimination. But, these are very big things. So, we have to do our part. If you go to see, we are very lucky. We have access to education and even then, nobody is even interested in studying.

If you give a child a book and tell him to study, he will turn   face in the opposite direction. There are very poor students who have a desire to study and have high goals. On the other hand, we say that we want to be a doctor, pilot, astronaut, and we refuse to study. In other words, it is right to have big goals, but we have to work towards it. Dr APJ Abdul Kalam once said: <span className='articles-span'>“Real education enhances the dignity of a human being and increases his or her self-respect”</span>.
            </p>
        </div>
        <div className="articles-card-footer">
            <p className="articles-card-footer-text">Shaun Mathew – V-A</p>
        </div>
    </div>

    <div className="articles-card">
        <div className="articles-card-header">
            <h2 className="articles-card-heading">All For the Greater Glory of God</h2>
        </div>
        <div className="articles-card-body">
            <p className="articles-card-main-text">
            In Tokyo Olympic many players participated from different countries and few of them won medals.

High jump competition of this Olympics was really great example of the spirit of sportsmanship, there were two players contesting for the Gold medal. One player was from Italy named Tamberi and other was from Qatar named Barshim. During the event Tamberi got injured and so he was unable to continue. Both players were having equal records. As Tamberi couldn’t continue, Barshim also decided to stop competing further. This way both, Tamberi and Barshim got gold medal. Instead being selfish Barshim decided to respect injured Tamberi’s feeling. It was great demonstration of the true spirit of sportsmanship.

True sportsmanship builds character, teaches respect, honour and kindness.
            </p>
        </div>
        <div className="articles-card-footer">
            <p className="articles-card-footer-text">Dhairya Shete – V – B</p>
        </div>
    </div>

    <div className="articles-card">
        <div className="articles-card-header">
            <h2 className="articles-card-heading">Selfless giving is the art of living.</h2>
        </div>
        <div className="articles-card-body">
            <p className="articles-card-main-text">
            Life is more meaningful if you live not  only for yourself but also to help others. Since I was a small child, my mother and teachers at school have always encouraged me to help poor and needy people.

During the pandemic, I got an opportunity to help our maid’s children in their studies and do my share of being a man for others. When the schools started with online classes, it was really difficult for all of us to get used to the new way of learning. These children, Alfiya and Shahid, were having a hard time adapting to the new e-learning system since they were never exposed to the world of the internet and mobile devices before. Being primary school students, they were even struggling to use cell phones for online classes. I guided them and set up their devices to use for online classes. They were also finding it difficult to understand and learn the topics taught by the teacher online. Their parents being not so educated, couldn’t help them much in their studies. When our maid expressed this concern to my mother, she decided to help them through this tough situation and called Alfiya and Shahid for studies to our home every weekend. My mother and I taught them Maths and Science.

With this personal experience, I learned that even small acts of helping others, such as teaching or mentoring, give us immense satisfaction. By serving the needy, we always get ultimate happiness and pleasure. In the end, I would like to say, helping one person might not change the whole world but it could change the world for that person. So always be kind, compassionate, and be the man/woman for others!
            </p>
        </div>
        <div className="articles-card-footer">
            <p className="articles-card-footer-text">Aditya Alhat – VI – B</p>
        </div>
    </div>

    <div className="articles-card">
        <div className="articles-card-header">
            <h2 className="articles-card-heading">Humanity Helpline</h2>
        </div>
        <div className="articles-card-body">
            <p className="articles-card-main-text">
            St. Ignatius Loyola, a Spanish soldier to a saint, was a man of God and for him his greatest service always came first.

In 1521 along with his fellow soldiers he had to fight a battle against the French where he was struck by a cannonball in the leg. During his difficult recovery he started reading books about the life of Christ and saints too. This helped him to follow the path towards God. He also realized that it is only God who teaches and guides him to do all good things.

<span className='articles-span'>God-Centered, Spiritual, Generous, Compassionate, Committed, Helpful are the Ignatian Qualities and for me helpful is the most important quality (especially in this pandemic situation)</span>.  Helping others is a noble quality. Helping others means being with them when they are struggling and need some assistance. When you help someone, you not only help them but yourself too. Along with them it also makes you happier. Helpfulness is something that spreads from one person to another. This creates a beautiful chain of people helping each other and assisting them in their tough times. This quality truly can change the world and make it a better place to live and leave for our further generations.
            </p>
        </div>
        <div className="articles-card-footer">
            <p className="articles-card-footer-text">Aaditya Pisal – VII – A</p>
        </div>
    </div>


    <div className="articles-card">
        <div className="articles-card-header">
            <h2 className="articles-card-heading">ALMIGHTY, TEACH US TO GIVE AND NOT TO COUNT THE COST</h2>
        </div>
        <div className="articles-card-body">
            <p className="articles-card-main-text">
            There is a saying that goes, “If you want happiness for an hour, take a nap. If you want happiness for a day, go fishing. If you want happiness for a year, inherit a fortune. If you want happiness for a lifetime help somebody”. For centuries the greatest thinkers have suggested the same thing. Happiness is found in helping others.

Right from my childhood my grandparents, parents and teachers have taught me to be loving, caring, contented with what we have and to be men for others. As a child I did not understand the deep meaning behind these simple acts of kindness, but lately during the pandemic I have understood these values can help to heal the world in many ways. One person cannot take on the whole world, but one person’s contribution can and will make a world of difference. The more we give, the more we stand to gain purpose and happiness in life. Helping others is a secret for living a life that is not only happier but also healthier, more productive and meaningful.

Help need not necessarily be monetary or with extraordinary things but by many small actions we can make a difference in others’ lives. Such as helping to pick up groceries for our elderly neighbor, volunteering in local community center or sharing with the needy. It can put up a smile and warm feeling in someone else’s heart. Let us all decide to men and women for others.
            </p>
        </div>
        <div className="articles-card-footer">
            <p className="articles-card-footer-text">IBRAHIM SHAH – VIII – A</p>
        </div>
    </div>

    <div className="articles-card">
        <div className="articles-card-header">
            <h2 className="articles-card-heading">Impact of Jesuit values</h2>
        </div>
        <div className="articles-card-body">
            <p className="articles-card-main-text">
            Jesuits have always been grounded in love for Christ and enliven by the spiritual vision of their founder St. Ignatius of Loyola to help others and seek God in all things right from 1540, the year when the Society of Jesus was founded. From the beginning they have been renowned due to their productive values of living and understanding life to a greater depth.

During the period of St. Ignatius, that is from 1491 to 1556, their core values of Cura Personalis (care for the whole self), Discernment, Magis (more), Reflection, Finding God in all things, Service rooted in justice and love, Solidarity and kinship had been spread across numerous countries inclusive of India, Brazil, Ethiopia, and the Congo region.

These Jesuit values have not only cultivated critical awareness of social and personal evil, but at the same time has also pointed out that God’s love is more powerful than anyone’s. Supplementary Values such as Critical Thinking, Leader Strength, Learning Passion, Global Understanding, Commitment to Social Justice, Self- Development, etc., have always been promoted by the Jesuits and thanks to them that it has also freely flown across several nations, territories, empires, and kingdoms resulting betterment of the society.

Due to the Jesuit principles and values, numerous people have been prepared for lifelong learning, for exploring the intersection between faith and culture, for embracing interfaith engagement and collaboration, for incorporating a global
and international dimension in order to achieve growth and education, and for
Inspiring students to change society and the world for the better.

Jesuits have inspired individuals to live purposeful and fulfilling lives of leadership and service to others and their values have and will always have a significant impact with a milestone of being instrumental to creating people who have empathy and who know how to care about people.

In short, they have helped to create a better world.
            </p>
        </div>
        <div className="articles-card-footer">
            <p className="articles-card-footer-text">Gonnade Atharv – IX – A</p>
        </div>
    </div>


    <div className="articles-card">
        <div className="articles-card-header">
            <h2 className="articles-card-heading">Mental Health</h2>
        </div>
        <div className="articles-card-body">
            <p className="articles-card-main-text">
            Mental Health is basically the core of our mind. It consists of emotions, psychological, and social well-being. It’s a vast and sensitive topic, not often talked about. Our perception, changes our mindset, changes who we really are and bothers us to some extent.

During the Covid- 19 Pandemic, concerns about anxiety and mental health have increased to a great extent. Study says that in January 2021, 41% of the adults reported symptoms of anxiety and depressive disorder. People have been exposed to immense stress levels which has also led some unfortunately to suicidal thoughts.

We have never understood what has actually brought us to this point of distress. It’s the circumstances, the time that took you into this. You don’t want to reveal your real situation to the people, because you don’t want them to feel that you are weak, weaker than them. You don’t want others to point out your depression, because you think you aren’t. You will get out of this, soon, no matter what your friends, family or anyone says about your mental health. I know how it feels to have been betrayed, and trust me they don’t know how it feels. But I know that you’ll surely get out of it. If someone says it’s easy to get out of the phase you’re in, it’s not, it’s hard. It isn’t a smooth transition like negative to positive, hopping over the equal sign, no. You’re insecure. I’m insecure. At some point in life everyone is.

You don’t feel like sharing with the world or your family. It’s completely fine. People feel sad about the most foolish things. Actually it isn’t foolish. We’re back to perception. There’s always going to be conflicts. You cannot tell someone to change their taste in music as you don’t like theirs. Life is meant to be lived.

Yolo. You only live once. You don’t want to waste a second of your precious life regretting that you could’ve done something else. You may think, life is repugnant, but the key task is to learn from life, never give up, never let go, enjoy every moment. Just give it some time. Let it get back on track.

“One small crack does not mean that you are broken, it means that you were put to the test and you didn’t fall apart.” – Linda Poindexter


            </p>
        </div>
        <div className="articles-card-footer">
            <p className="articles-card-footer-text">Vedant Rathi – X – B</p>
        </div>
    </div>

    <div className="articles-card">
        <div className="articles-card-header">
            <h2 className="articles-card-heading">Present day youth- a beacon of hope</h2>
        </div>
        <div className="articles-card-body">
            <p className="articles-card-main-text">
            The definition of beacon is- a person or things that warns, guides or offers support. So in this context youth is definitely a beacon of hope. Most of the discoveries, ideas and innovation and research and development takes place in youth because youth’s mind is inquisitive, it questions and questions, evaluates issues to arrive at optimal solutions. What would the world be if there were no discoveries, nothing new to look up to, to learn or to question?

There would have been “eureka moments”. Present day youth is ready for anything and the present day pandemic has taught not only the youth but everyone to take the bull by its horn. At the same time taught us not to take anything lightly. Youth of today does not believe in beating around the bush but is taking all the efforts to help people in need, educating the uneducated, teaching what is the right way of dealing with this crisis in which the entire humanity is together.

No matter what, the young, the old are each other’s support system. All humanity would have gone down if all the generations were not supportive of each other during the pandemic and otherwise also, and the youth will definitely lead the path as our country consists of almost 356 million of young population.

The very word “hope” as per the dictionary means a ‘belief’ that something you want will happen and youth of today is destined to be a beacon of hope for all mankind. Hence youth of today knows when to offer friendship and when to put foot down firmly. Youth of today is compassionate, loving, respectful, peace loving and ready to take weapons to defend itself, family and country. Youth is definitely- a beacon of hope and youths will make the world a better place to live in- Amen to that .


            </p>
        </div>
        <div className="articles-card-footer">
            <p className="articles-card-footer-text">Anusha Savalekar – XII – A</p>
        </div>
    </div>
</div>
    </div>
    </>
  )
}

export default Articles
