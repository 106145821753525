import React, {useEffect} from 'react'
import './General.css'
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the AOS styles
import { Helmet } from 'react-helmet';

const General = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set the duration of the animation (in ms)
      once: true,     // Whether the animation should happen only once - while scrolling down
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <>
    <Helmet>
      <title>General Info</title>
      <meta name="Loyola - General Info" description="More about Loyola High School Pune"/>
      <link rel="canonical" href="/general" />
    </Helmet>
    <div className='general' id="general-info">
      <div className="general-label" data-aos="fade-right">
            <div className="label-main">Rules & Regulations</div>
            <div className="label-subtext">Safety of our Students</div>
        </div>

        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">Guidelines</div>
          <div className="general-info-main">Both parents and pupils should keep in mind the following principles of discipline laid down in the Secondary School Code (1972), Rule 56.
Pupils should realise that they are responsible to the School authorities not only for their conduct in school but also for their general behaviour outside. Any reported or observed objectionable conduct out of the school on the part of pupil shall make him liable to disciplinary action. Parents or guardians are given to understand that they cannot dictate to the management and that the management has a right to say on what conditions it will admit or retain pupil in their Schools.</div>
        </div>

        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">Attendance and Behaviour</div>
          <div className="general-info-main">Regular attendance and study habits, prompt obedience, courtesy in speech and conduct, cleanliness of dress and person are expected from each boy. Hence, irregular attendance, habitual idleness, unsatisfactory application to studies, disobedience, objectionable moral influence, and unsatisfactory conduct in and out of the school will justify the dismissal of a boy.

Students coming to school without doing their home-work, without books or found misbehaving in class are likely to be keep after school hours. For students of Std V to VII prior warning will be given, for students of Std VIII to XII, no prior warning will be given if kept after school.

For serious faults, PINK CARDS are given to the boy. Parents are then expected to sign and return the same to the Principal. For more serious faults GREY CARDS are given; parents are then expected to come and see the Principal on the next school day.</div>
        </div>



        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">School Uniform</div>
          <div className="general-info-main">On school days and for school functions all the boys must be in school uniform: light beige shirt with the school monogram and dark beige shorts. Sikhs from Std. IV onwards are expected to wear turban. Boys in classes X, XI and XII may use long trousers (no drainpipes or bell bottoms).

In addition all the boys from class I to XII must have a P.T. uniform: House Colour shorts and singlet, and shoes (which are worn during P.T. periods only).

Uniform material is available with Apsara Dresses, Model Colony, Pune- 5 & also at Shoe Land NCL Shopping Centre, Pune-8.

Boys are expected to come to school in their school uniform. If for some valid reason a boy cannot wear his uniform, he should bring a note of excuse from his parents/ guardians. Anyone coming to school without an excuse is liable to be sent home.</div>
        </div>


        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">Calendar</div>
          <div className="general-info-main">All the students should have their own calendar and they should bring it to school daily. Parents should check the calendar of their children at least once a week.

A boy who mutilates or loses his calendar, progress card or library card will have to pay a cost of Rs. 50/- and buy a new one.

Language
When on the School premises, all have to speak in English at all times.

Learning of Marathi is compulsory in the Primary and Secondary Section of the school.

Absence, Lateness and Illness
No boy who has been absent from school or is late for school will be admitted into class without the Principal’s signature in the calendar.

In case of illness, on returning to school the student must bring a note in the calendar from his parents / guardians certifying to the fact. Failure to produce this note may oblige the school to send the boy home even if during school hours.

A boy who has been suffering from an infectious disease must bring a doctor’s certificate declaring him fit to go to school without danger of infecting others. On his rejoining school, he should be accompanied by his parent.

The Principal should be informed as soon as possible if, due to fitness, a boy is likely to be absent for more than three days . A medical certificate should then be produced.</div>
        </div>



        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">Leave of Absence</div>
          <div className="general-info-main">No leave of absence is granted except on previous written application from their parents / guardians, and then only for serious reasons which should be mentioned. If a boy is to be absent for more than three days, the parents / guardians should meet the Principal personally to get this leave sanctioned.

Absence without leave renders a boy liable to a fine. If this absence is prolonged for more than one week, especially before or after holidays the boy’s name is to be struck off the rolls.

No extension of leave will be granted either prefixing or sub fixing with major vacations (Christmas, Diwali and Summer) under any circumstances.

</div>
        </div>

        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">Games</div>
          <div className="general-info-main">Games may be played on the sports ground of the school till 5:30 p.m. After 6:30 p.m. a boy should be at home doing his homework. Students who absent themselves from games or refuse to represent the school will have a remark made in their final L.C.
At no time should boys be found playing, running chasing each other etc., in the corridors, in the classrooms or in the hall. Eating in the classrooms is strictly forbidden. During intervals (short or long) students should not linger in the classroom.
</div>
        </div>


        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">School Property          </div>
          <div className="general-info-main">All students must take care of school property including the chairs, desks, glasspanes, books, etc. Those who damage or destroy school property will have to make good the damage done. If no students own up to the damage done, a common fine will be charged for all.

Care is taken to ensure that no untoward mishap occurs while your child is with us. However, accidents can take place at any time. Should an accident occur, either on the school premises or while the children are on the school bus, or at a picnic / outing / camp, the school will bear no financial responsibility.

The school authorities will not be liable or responsible for any accident or injury that may occur on the playground, or anywhere else on the school campus, or at picnic / educational excursions.

Boys found jumping through class windows etc. and throwing stones at trees or at others will be dealt with severely.

</div>
        </div>



        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">Personal Belongings</div>
          <div className="general-info-main">A boy must look after his own belongings: Coats, raincoats, caps, jerseys, umbrellas, tiffin boxes etc. and all books must be marked with the name and class of the owner. It is not advisable for a boy to have money or any valuable articles with him.

Boys from Std. I to VI should not wear wristwatches to school.

Boys of Std. V and VI may use wristwatches on exam days only.

Student should not possess tazos, Pokemon cards or any other type of playing cards, personal diaries and other electronics gadgets particularly mobiles & video games. Such articles will be confiscated.

The School does not make itself responsible for books, clothes or other articles lost or stolen.

</div>
        </div>



        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">Personal Transport</div>
          <div className="general-info-main">Cycles must be kept-on the stands provided by the school. Cycles found outside will be confiscated. They will be given back after paying a fine.

The school takes no responsibility if the cycles are found missing or damaged. No students are allowed to come to school driving motorised vehicles.

</div>
        </div>

        <div className="general-info" data-aos="fade-up">
          <div className="general-info-heading">Principal's Permission</div>
          <div className="general-info-main-li">
            <ul>
              <li>To make a collection for any purpose whatever</li>
              <li>To arrange for a party, picnic or meeting</li>
              <li>To join a sports meet or to play in a game not arranged by the school</li>
              <li>To give presents to teachers or to stage demonstrations against them</li>
              <li>To bring books, periodicals, or newspapers into the school premises</li>
              <li>To bring electronic such as calculators, C.D. s, mobile phones, transistors, cassette players and cassettes, toys, mathematical puzzles, watches which chime etc. The same will be confiscated if found without due authorisation.</li>
              <li>Photographs to be attested by the principal, should be taken in the school uniform only</li>
            </ul>
</div>
        </div>
    </div>
    </>
  )
}

export default General
