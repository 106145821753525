import {useEffect} from 'react'
import './CoCurricular.css'
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the AOS styles

const CoCurricular = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set the duration of the animation (in ms)
      once: true,     // Whether the animation should happen only once - while scrolling down
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <div className='co' id='cocurricular'>
      <div className="co-text">
        <div className="co-title" data-aos="fade-up">
          Co-Curricular
          <div className="co-title-sub">Ideas to Impacts</div>
        </div>

        <div className="co-main" data-aos="fade-up" delay="200">
        Co-Curricular Activities (CCAs), previously known as <span>Extra-Curricular Activities</span> serve to promote a variety of activities which all school students must attend alongside the standard study curriculum.
Loyola High School believes that CCAs are an essential means to instill <span>active social interaction</span> and holistic education in a student. CCAs also promote the <span>formation and growth</span> of other important qualities such as leadership, healthy recreation, self-discipline and self-confidence.
The scope of CCAs is wide due to a nearly in-exhaustive list of interests. CCAs are held during and outside standard school hours and the activities depend on the nature of CCA. In addition, students are <span>given some responsibilities</span>, providing important training to prepare them for the adult world.
        </div>
      </div>

        <div className="scouts-text" data-aos="fade-up">
          <div className="scouts-title" data-aos="fade-up">
            Scouts & Services
            <div className="co-title-sub">Awareness of Responsibility</div>
          </div>
          <div className="scouts-main" data-aos="fade-up" delay="200">
          <span>“Be Prepared”</span>- the scout motto – forms the base to build and develop a boy’s abilities. The school conducts a physical test of all boys wanting to join the Loyola Scouts troop. Along with regular <span>Bharat Scouts</span> training, the boys have to attend one trek and one camp every year. They train in <span>basic survival techniques</span>, and even learn to cook their own food. A few parents volunteer to supervise and instruct the boys on the <span>basics of cooking</span> beforehand. (No one goes hungry!). The scouts also help in the smooth <span>organisation</span> of various school functions.
          </div>

          <div className="scouts-title2" data-aos="fade-up">
            Services
            <div className="co-title-sub">Help & Maintain</div>
          </div>
          <div className="scouts-main" data-aos="fade-up" delay="200">
          Here are a few activities carried out by boys opting for Social Service:
Within the School:
            <div className="submain">
              <div><i className="fa-solid fa-circle-check"></i>Keeping the school campus clean</div>
              <div><i className="fa-solid fa-circle-check"></i>Marking the sports ground for sports events</div>
              <div><i className="fa-solid fa-circle-check"></i>Helping with various school functions</div>
              <div><i className="fa-solid fa-circle-check"></i>Selecting a nearby village and educating the inhabitants on the importance of cleanliness</div>
              <div><i className="fa-solid fa-circle-check"></i>Choosing a historical monument or location and undertaking a cleanliness drive
              </div>
            </div>
          </div>
        </div>

        <div className="clubs">
          <div className="clubs-title">Clubs
            <div className="co-title-sub">Grow, Learn, Thrive</div>
          </div>
          <div className="clubs-info">Various clubs are engaged in development of the student's knowledge through creativity. Helping students solve real world problems.</div>
        </div>

        <div className="co-card-container" data-aos="fade-up">
          {/*Ecology Club */}
          <div className="club-card">
            <div className="heading">
              Ecology Club
            </div>
            <div className="mini-card-container">
            <div className="mini-card">
              RECYCLE
            </div>
            <div className="mini-card">
              NATURE
            </div>
            <div className="mini-card">
              CLEANLINESS
            </div>
            <div className="mini-card">
              REDUCE
            </div>
            <div className="mini-card">
              AWARENESS
            </div>
            </div>

            <div className="mini-card-text">
          The Ecology Club aims to make the students aware of their planet and environment through on field activities.
          </div>

          <div className="content-label">
            What's Included:
          </div>
          <div className="content">
            <ul>
              <li>Making our campus less wasteful.</li>
              <li>Cleanliness</li>
              <li>Raising awareness for eco-friendly causes.</li>
              <li>Promoting environment friendly habits like Reducing, Reusing, and Recycling.</li>
            </ul>
          </div>
          </div>

          {/*Ecology Club --- END*/}

          {/*Literary Club */}

          <div className="club-card">
            <div className="heading">
              Literary Club
            </div>
            <div className="mini-card-container">
            <div className="mini-card">
              CONNECT
            </div>
            <div className="mini-card">
              LEARN
            </div>
            <div className="mini-card">
              NETWORK
            </div>
            <div className="mini-card">
              COMMUNICATE
            </div>
            </div>

            <div className="mini-card-text">
            The Literary Club of Loyola High school and Junior College is a platform for children to connect with themselves and with others through various creative activities.
          </div>
          <div className="content-label">
            What's Included:
          </div>
          <div className="content">
            <ul>
              <li>Connecting & Networking</li>
              <li>Present Ideas</li>
              <li>Growth Oppurtonity</li>
              <li>Inspiration</li>
              <li>Ideation</li>
            </ul>
          </div>
          </div>

          {/*Ecology Club --- END*/}

          {/*Science Club */}

          <div className="club-card">
            <div className="heading">
              Science Club
            </div>
            <div className="mini-card-container">
            <div className="mini-card">
                TECH
            </div>
            <div className="mini-card">
              DEVELOPMENT
            </div>
            <div className="mini-card">
              SCIENCE
            </div>
            <div className="mini-card">
              THINKING
            </div>
            <div className="mini-card">
              CREATIVITY
            </div>
          </div>
          <div className="mini-card-text">
          The Science Club aims to inculcate scientific attitude, problem solving skills, habits of exploration and providing challenging opportunities to the member students.
          </div>

          <div className="content-label">
            What's Included:
          </div>
          <div className="content">
            <ul>
              <li>Activities & Lectures</li>
              <li>Problem Solving Skills</li>
              <li>Challenging Opportunities</li>
              <li>Latest Tech</li>
            </ul>
          </div>
          </div>
          </div>

          {/*Science Club --- END*/}
    </div>
  )
}

export default CoCurricular
