import React from 'react'
import Sidebar from './Sidebar'
import './Navbar.css'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="hamburger-container">
      <Sidebar /> {/* Place the Sidebar component here */}
    </div>
    <img src="loyolaloogo.png" alt="" width="50px" height="50px"/>
    <div className="logo">
      <h1>Loyola</h1>
    </div>
    
  </nav>
  )
}

export default Navbar
