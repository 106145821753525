import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faChevronDown, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import { Link, useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setActiveDropdown(null); // Close all dropdowns when the sidebar is closed
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (isOpen && !event.target.closest('.sidebar') && !event.target.closest('.hamburger')) {
      setIsOpen(false);
      setActiveDropdown(null); // Close any open dropdowns as well
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavigation = (path, id) => {
    navigate(path);
    if (id) {
      setTimeout(() => scrollToSection(id), 100); // Delay to ensure page is loaded
    }
    toggleSidebar();
  };
  return (
    <div>
      <div className="hamburger" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="2x"/>
      </div>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><Link  to="/" onClick={toggleSidebar}><i className="fa-solid fa-house mr-10"></i>Home</Link></li><hr/>
          <li><Link to="/general" onClick={() => handleNavigation('/general', 'general-info')}><i className="fa-solid fa-comment mr-10"></i>General Info</Link></li><hr/>
          <li><Link to="/about" onClick={() => handleNavigation('/about', 'about')}>
          <i className="fa-solid fa-circle-info mr-10"></i>About</Link>
            <FontAwesomeIcon 
              icon={faCaretDown} 
              onClick={() => toggleDropdown(0)} 
              className={`dropdown-icon ${activeDropdown === 0 ? 'rotate' : ''}`}
            />
            <ul className={`dropdown ${activeDropdown === 0 ? 'open' : ''}`} >
              <li className='dropdown-links'><Link to="/about/message" onClick={() => handleNavigation('/about/message', 'message')}><i className="fa-solid fa-message mr-10"></i>Principal's Message</Link></li>
            </ul>
          </li><hr/>
          <li>
            <i className="fa-solid fa-table-list mr-10"></i><Link to="/admission" onClick={() => handleNavigation('/admission', 'admission')}>Admissions</Link>
          </li><hr/>
          <li><Link to="/contact" onClick={() => handleNavigation('/contact', 'connect')}><i className="fa-solid fa-phone mr-10"></i>Contact</Link></li><hr/>
          <li><Link to="/cocurricular" onClick={() => handleNavigation('/cocurricular', 'cocurricular')}><i className="fa-solid fa-football mr-10"></i>Co-curricular</Link></li><hr/>
          <li><Link to="/today" onClick={() => handleNavigation('/today', 'case')}><i className="fa-solid fa-school mr-10"></i>Today's Loyolites</Link>
          <FontAwesomeIcon 
              icon={faCaretDown} 
              onClick={() => toggleDropdown(2)} 
              className={`dropdown-icon ${activeDropdown === 2 ? 'rotate' : ''}`}
            />
            <ul className={`dropdown ${activeDropdown === 2 ? 'open' : ''}`} >
              <li className='dropdown-links'><Link to="/today/articles" onClick={() => handleNavigation('/today/articles', 'articles')}><i className="fa-regular fa-newspaper mr-10"></i>Articles</Link></li>
            </ul>
            </li><hr/>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
