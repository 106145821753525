import React, {useEffect} from 'react'
import './Footer.css'
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the AOS styles
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({
          duration: 1000, // Set the duration of the animation (in ms)
          once: true,     // Whether the animation should happen only once - while scrolling down
          easing: 'ease-in-out',
        });
      }, []);

      const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    
      const handleNavigation = (path, id) => {
        navigate(path);
        if (id) {
          setTimeout(() => scrollToSection(id), 100); // Delay to ensure page is loaded
        }
      };

  return (
    <div className='footer'>
        <div className="footer-label" data-aos="fade-right">
            <div className="label-main">Got some doubts?</div>
            <div className="label-subtext">Let's Talk</div>
            <div className="footer-nav">
            <i className="fa-solid fa-chevron-right"></i><Link to="/contact" onClick={() => handleNavigation('/connect', 'connect')}>Tell us everything</Link>
            </div>
        </div>
        <div className="section-container" data-aos="fade-up">
            <div className="section">
                <div className="section-title">School Hours</div><hr />
                <div className="section-content">
                    <div className="section-subcontent">
                        <div className="light-gray section-subcontent1">Regular Hours</div>
                        <div>Monday to Friday</div>
                        <div>8:30 a.m. to 3:40 a.m.</div>
                    </div>
                    <div className="section-subcontent">
                    <div className="light-gray section-subcontent1">Morning School Hours</div>
                    <div>Monday to Friday</div>
                    <div>7:50 a.m. to 1:05 a.m.</div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="section-title">Principal's Hours</div><hr />
                <div className="section-content">
                    <div className="section-subcontent">
                        <div className="light-gray section-subcontent1">Regular School Days</div>

                        <div>9:30 a.m. to 10:30 a.m.</div>
                    </div>
                    <div className="section-subcontent">
                    <div className="light-gray section-subcontent1">Morning School Hours</div>
                    <div>Monday to Friday</div>
                    <div>7:50am to 1:05pm</div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="section-title">Thank You!</div><hr />
                <div className="section-content">
                    <div className="section-subcontent">
                        <div>Thank you to all students, staff, teachers and parents for all the support you are giving us.</div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="section-title">ALUMNI</div><hr />
                <div className="section-content">
                    <div className="section-subcontent">
                        <div>The school would be delighted to hear from former students and staff. Please contact us or send us an email.</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="hr">
            <hr />

            <div className="social-links">
                <div className="social-title">
                    <h1>Loyola</h1>
                </div>
                <div className="links">
            <a><i className="fa-brands fa-instagram fa-xl"></i></a>
            <a href="https://www.linkedin.com/school/loyola-high-school-pune/"><i class="fa-brands fa-linkedin-in fa-xl"></i></a>
            <a><i className="fa-brands fa-facebook fa-xl"></i></a>
            <a><i className="fa-brands fa-twitter fa-xl"></i></a>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
