import {useEffect} from 'react'
import './About.css'
import 'aos/dist/aos.css';  // Import the AOS styles
import AOS from 'aos';
import { Helmet } from 'react-helmet';

const About = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000, // Set the duration of the animation (in ms)
          once: true,     // Whether the animation should happen only once - while scrolling down
          easing: 'ease-in-out',
        });
      }, []);

  return (
    <>
    <Helmet>
      <title>About - Loyola</title>
      <meta name="Loyola - About" description="Loyola High School history & glimpse"/>
      <link rel="canonical" href="/about" />
    </Helmet>
    <div className='about' id="about">
      <div className="about-label" data-aos="fade-right">
            <div className="about-label-main">About Us</div>
            <div className="about-label-subtext">Who are we ?</div>
            <div className="about-nav">
            The purpose of the school is not only to impart instruction but also to educate the whole boy: to train his intellectual, moral, emotional, physical and aesthetic faculties.
            </div>
        </div>

        <div className="more-about">
            <div className="about-card" data-aos="fade-up">
                <div className="about-card-heading">
                    Objectives
                </div>
                <div className="about-card-main">

The vision of Jesuit education, inspired by Jesus Christ and St. Ignatius of Loyola, emphasizes character formation and holistic development. Loyola High School and Junior College aims to create "Men-for-Others," students with deep faith, moral integrity, and a commitment to selfless service. The school accepts students from all backgrounds, focusing on intellectual, moral, emotional, physical, and aesthetic growth. Education at Loyola seeks to develop students' character, encourage excellence, and foster social responsibility. Co-curricular activities, including a strong sports program and swimming, are integral to this holistic approach.
                </div>
            </div>


            <div className="about-card" data-aos="fade-up" delay="400">
                <div className="about-card-heading">
                    History
                </div>
                <div className="about-card-main">
                Loyola High School and Junior College, an English medium school founded in 1961, is named after St. Ignatius of Loyola, founder of the Society of Jesus. Recognized by the Government of Maharashtra, the school prepares students for the SSC (Std. X) and HSC (Std. XII) exams. Managed by the Poona Jesuit Schools Society, it enjoys minority rights under the Indian Constitution. Part of a global Jesuit network, Loyola is among over 150 Jesuit-run educational institutions in India, serving students from all social and linguistic backgrounds, while maintaining a commitment to the Christian community and the nation's educational goals.
                </div>
            </div>
        </div>

        <div className="about-label" data-aos="fade-right">
            <div className="about-label-main">Faculty & Staff</div>
            <div className="about-label-subtext">Our Foundations</div>
            <div className="about-nav">
            Loyola has had great teachers in the past. Their legacy of inspired teaching was like a blazing torch that has been ably carried forward by the current teachers, and will continue to burn brightly in the future. Here is a summary of the present strength of the staff.
            </div>
        </div>

        <div className="about-card-container" data-aos="fade-up">
            <div className="about-cardx" data-aos="fade-up">
                <img src="principal.jpg" alt="" width="100%"/>
                <div className="about-card-text">
                    <div className="case-card-tags">Principal</div>
                    <div className="case-card-name">Fr. Nelton Thomas</div>
                </div>
            </div>

            <div className="about-cardx" data-aos="fade-up">
                <img src="primaryco.jpg" alt="" width="100%"/>
                <div className="about-card-text">
                    <div className="about-card-tags">Primary Co-ordinator</div>
                    <div className="about-card-name">Mrs. Premila Thomas</div>
                </div>
            </div>

            <div className="about-cardx" data-aos="fade-up">
                <img src="secondaryco.jpg" alt="" width="100%"/>
                <div className="about-card-text">
                    <div className="about-card-tags">Secondary Co-ordinator</div>
                    <div className="about-card-name">Mrs. Sophia Manuel</div>
                </div>
            </div>

            <div className="about-cardx" data-aos="fade-up">
                <img src="collegeco.jpg" alt="" width="100%"/>
                <div className="about-card-text">
                    <div className="about-card-tags">College Co-ordinator</div>
                    <div className="about-card-name">Mrs. Maliga Patham</div>
                </div>
            </div>
            </div>
    </div>
    </>
  )
}

export default About
