import {useEffect} from 'react'
import './Case.css'
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the AOS styles
import Fact from './Fact';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Case = () => {

    useEffect(() => {
        AOS.init({
          duration: 2000, // Set the duration of the animation (in ms)
          once: true,     // Whether the animation should happen only once - while scrolling down
          easing: 'ease-in-out',
        });
      }, []);

  return (
    <>
    <Helmet>
      <title>Today's Loyolites - Loyola</title>
      <meta name="Loyola - Our Stars" description="Have a glimpse of the Loyolite's who thrive in all sectors"/>
      <link rel="canonical" href="/today" />
    </Helmet>
    <div className='case' id='case'>
      <div className="case-label-container" data-aos="zoom-in">
        <div className="case-label">
        Loyola High School’s history is replete with lists of achievers.
        </div>

        <div className="case-nav">
        <i className="fa-solid fa-chevron-right"></i><Link to="/contact">Contact us</Link>
        </div>
        </div>

        <div className="case-footer">
        <div className="case-footer-label">
            <div className="label-main" data-aos="fade-left">Glimpse of Pride</div>
            <div className="label-subtext" data-aos="fade-right">Boys who excel in academics, who overcome odds to wrest inter-school championships, and boys who still remember to write an ode to their mothers. Glimpses of this year’s academic and sports results cement our firm belief in the value of a holistic comprehensive education.</div>
        </div>
        </div>

        <div className="case-card-container">
            <div className="case-card" data-aos="fade-up">
                <img src="sport1.jpg" alt="" width="100%"/>
                <div className="case-card-text">
                    <div className="case-card-tags">Football</div>
                    <div className="case-card-name">LOYOLA CUP u-12 Runners Up</div>
                </div>
            </div>

            <div className="case-card" data-aos="fade-up">
                <img src="sport2.jpg" alt="" width="100%"/>
                <div className="case-card-text">
                    <div className="case-card-tags">Football</div>
                    <div className="case-card-name">LOYOLA CUP u-14 Winners</div>
                </div>
            </div>

            <div className="case-card" data-aos="fade-up">
                <img src="sport3.jpg" alt="" width="100%"/>
                <div className="case-card-text">
                    <div className="case-card-tags">win win</div>
                    <div className="case-card-name">LOYOLA CUP u-16 Winners</div>
                </div>
            </div>

            <div className="case-card" data-aos="fade-up">
                <img src="sport4.jpg" alt="" width="100%"/>
                <div className="case-card-text">
                    <div className="case-card-tags">Basketball</div>
                    <div className="case-card-name">u-19-II Runners Up</div>
                </div>
            </div>

            <div className="case-card" data-aos="fade-up">
                <img src="sport5.jpg" alt="" width="100%"/>
                <div className="case-card-text">
                    <div className="case-card-tags">Hockey</div>
                    <div className="case-card-name">u-16 Winners</div>
                </div>
            </div>

            <div className="case-card" data-aos="fade-up">
                <img src="sport6.jpg" alt="" width="100%"/>
                <div className="case-card-text">
                    <div className="case-card-tags">Hockey</div>
                    <div className="case-card-name">Fr. Schoch Hockey Cup  Under–12–RunnersUp</div>
                </div>
            </div>

            <div className="case-card" data-aos="fade-up">
                <img src="sport7.jpg" alt="" width="100%"/>
                <div className="case-card-text">
                    <div className="case-card-tags">Hockey</div>
                    <div className="case-card-name">Fr. Schoch Hockey Cup  Under – 17 – Winners</div>
                </div>
            </div>
        </div>
        <hr className='hr1'></hr>
        <Fact/>
    </div>
    </>
  )
}

export default Case
