import React from 'react'
import './Message.css'
import {Helmet} from 'react-helmet'

const Message = () => {

  return (
    <>
    <Helmet>
      <title>Principal's Message - Loyola</title>
      <meta name="Loyola - The Culture" description="Average culture of a school in Pune"/>
      <link rel="canonical" href="/about/message" />
    </Helmet>
    <div className='message' id='message'>
      <div className="message-heading">
        Principal's Message
      </div>
      <div className="message-subheading">
        The Loyola Culture
      </div>

      <div className="messsage-main">
      <img src="sport1.jpg" alt=""/>
        <div className="message-main-img">
            
        </div>

        <div className="message-main-text">
        <span>Imparting quality, formative and sustainable education</span> has been the <span>legacy of Jesuits</span> across the world. <span>St. Ignatius of Loyola</span>, the very name which our School is named after, eight years after the founding of the <span>Jesuit Order</span>, centuries ago that is way back in 1548, responded to the citizens of Messina, Italy and started a <span>tuition-free school</span> for needy students. Since then Jesuits have been educating students in the <span>Ignatian legacy</span> of <span>care for the whole person</span>: his / her mind, his / her heart his / her soul and his / her service to others.
The Ignatian Legacy today is treasured in <span>Ignatian Pedagogical Paradigm (IPP)</span> which has active and potential means of <span>integrated development and growth</span>. The IPP mainly comprises of five elements, namely, <span>Experience, Reflection, Action, Context and Evaluation</span>. These elements enable us deeply to grasp and understand your <span>consciousness, conscience and character</span>, which help us envision life, full of <span>spiritual and pragmatic learning</span>, understanding, knowledge, experience and expertise.
Thus Loyola remaining loyal to Ignatian Legacy is imparting <span>unique education</span> which makes our students first <span>responsible leaders</span> of their life and then leaders for the others with love and service, light and truth. The foundational education, the soft skills, experience and exposure imparted to our students make them Knowledgeable, Intellectual and Spiritual inviting them to serve God in their family and through neighbours – <span>service to humanity</span>.
Dear Parents, our students are getting formed in the best ambience of learning and spiritual arena. They are provided with the best possible opportunities for life. They are continuously and consciously engaged in intellectual and transformational education and activities. The School gets them constructively involved in the life situations that are stimulating and conducive and healthy response for their all-round growth. Loyalites are taught to meet the challenges of life and to challenge their own life for <span>intellectual and spiritual formation</span>.
Dear Staff and Dear Parents, hence our duty is to help our students to think intellectually and spiritually, logically and critically, to make good discernment and decision for their integrated growth and constructive development. Dear Parents, our staff acts as <span>mentors</span> and at times as <span>parents</span> to the students. Together with you they constantly accompany them. The Staff encourages them to participate in various competitions, debates, Olympiads, Exhibitions and makes them winner and mirror to their life. It is thus our joint responsibility to treasure <span>Ignatian Legacy of Intellectual and spiritual excellence</span> in life.
Our unique education empowers our students and it also empowers us. It is our quest to prepare <span>men and women for others</span>. The IPP with its components: Experience, Reflection, Action, Context and Evaluation serve as a mirror to all of us.
        </div>
      </div>
    </div>
    </>
  )
}

export default Message
