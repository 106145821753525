import {useEffect} from 'react'
import './Home.css'
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the AOS styles
import { faBars, faTimes, faChevronDown, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Home = () => {

  useEffect(() => {
    AOS.init({
      duration: 2000, // Set the duration of the animation (in ms)
      once: true,     // Whether the animation should happen only once - while scrolling down
      easing: 'ease-in-out',
    });
  }, []);

  const handleFaviconClick = () => {
    window.scrollBy({ top: 600, behavior: "smooth" }); // Adjust the top value to the number of pixels you want to scroll down
  };

  return (
    <div className='home'>
      <div className="home-text" >
        <div className="home-label"data-aos="fade-up">
          HOME
        </div>

        <div className="home-content"data-aos="fade-up" delay="2000">
          <div className="home-main">
            Loyola School
          </div>
          <div className="home-subtext" data-aos="fade-up" delay="2000">
            We're the resource corridor for the young minds.
          </div>
        </div>

        <div className="home-nav" >
          <div className="home-nav-text" onClick={handleFaviconClick}>
            More
          </div>
          <i onClick={handleFaviconClick} className="fa-solid fa-caret-down"></i>
        </div>
        </div>
    </div>
  )
}

export default Home
