import {useEffect} from 'react'
import './Home2.css'
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the AOS styles
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home2 = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000, // Set the duration of the animation (in ms)
          once: true,     // Whether the animation should happen only once - while scrolling down
          easing: 'ease-in-out',
        });
      }, []);

  return (
    <>
    <Helmet>
    <title>Home Page - Loyola High School & Junior  College, Pune - Pashan Road</title>
        <meta name="loyola culture" content="Preparing your kids for the real world." />
        <link rel="canonical" href="https://loyolahighschoolpune.com/" />
        <meta property="og:title" content="Home Page - Loyola High School" />
        <meta property="og:description" content="Welcome to Loyola, we are based in Pashan Pune." />
        <meta property="og:url" content="https://loyolahighschoolpune.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://loyolahighschoolpune.com/homepageloyola.jpg" />



        <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home Page - Loyola High School" />
                <meta name="twitter:description" content="Welcome to Loyola, we are based in Pashan Pune." />
                <meta name="twitter:image" content="https://loyolahighschoolpune.com/homepage2.jpg" />


        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "Home Page",
            "url": "https://loyolahighschoolpune.com/",
            "description": "The perfect second home for growth."
          })}
        </script>
    </Helmet>
    <div className='home-2' id='home-2'>
        <div className="label-container">
        <div className="home2-label">
        Making students aware of their potential is our success.
        </div>

        <div className="home2-nav">
        <i className="fa-solid fa-caret-right"></i><Link to="/contact">Contact us</Link>
        </div>
        </div>

        <div className="card">
            <div className="card-text-conatiner">
            <div className="card-text">
                <div className="card-text-tag">
                    LOYOLA - STUDENTS
                </div>

                <div className="card-text-title" data-aos="fade-up" delay="200">
                    <h1 className='black'>STUDENTS</h1>
                    <h1 className='ok'>Resources for students</h1>
                </div>

                <div className="card-text-main" data-aos="fade-up" delay="200">
                Loyola High School’s history is replete with lists of achievers. Boys who excel in academics, who overcome odds to wrest inter-school championships, and boys who still remember to write an ode to their mothers.
                </div>

                <div className="card-text-nav">
                <i className="fa-solid fa-caret-right"></i><Link to="/today">Visit</Link>
                </div>

                <div className="card-text-footer">
                    Students + Players - Our Biggest Win (yet)! 
                </div>
            </div>
            </div>
            <div className="card-img" data-aos="fade-left">
                <img src="homepage2.jpg" alt="" width="100%" height="100%"/>
            </div>
            
        </div>

        <div className="card2">
        <div className="card2-img" data-aos="fade-right">
                <img src="homepage1.jpg" alt="" width="100%" height="100%"/>
            </div>
            <div className="card2-text-conatiner">
            <div className="card2-text">
                <div className="card2-text-tag">
                    LOYOLA - PARENTS
                </div>

                <div className="card2-text-title" data-aos="fade-up" delay="200">
                    <h1 className='black'>PARENTS</h1>
                    <h1 className='ok'>Parents love us</h1>
                </div>

                <div className="card2-text-main" data-aos="fade-up" delay="200">
                Parents / guardians are requested to be present in school when called for, after unit tests and examinations, to meet teachers about their son’s/wards’ progress and for P.T.A. meetings.
                </div>

                <div className="card2-text-nav">
                <i className="fa-solid fa-caret-right"></i><Link to="/general">Visit</Link>
                </div>

                <div className="card2-text-footer">
                    Parent's Turst - Our goal! 
                </div>
            </div>
            </div>
            
            
        </div>

        <div className="card3">
            <div className="card-text-conatiner">
            <div className="card-text">
                <div className="card-text-tag">
                    LOYOLA - FACULTY
                </div>

                <div className="card-text-title" data-aos="fade-up" delay="200">
                    <h1 className='black'>FACULTY</h1>
                    <h1 className='ok'>Professional Faculty & Staff</h1>
                </div>

                <div className="card-text-main" data-aos="fade-up" delay="200">
                Loyola has had great teachers in the past. Their legacy of inspired teaching was like a blazing torch that has been ably carried forward by the current teachers, and will continue to burn brightly in the future.
                </div>

                <div className="card-text-nav">
                <i className="fa-solid fa-caret-right"></i><Link to="/about">Visit</Link>
                </div>

                <div className="card-text-footer">
                    Our Faculty & Staff - Our Foundation! 
                </div>
            </div>
            </div>
            <div className="card-img" data-aos="fade-left">
                <img src="homepage3.jpg" alt="" width="100%" height="100%"/>
            </div>
            
        </div>

        <div className="card2">
        <div className="card2-img" data-aos="fade-right">
                <img src="homepage4.jpg" alt="" width="100%" height="100%"/>
            </div>
            <div className="card2-text-conatiner">
            <div className="card2-text">
                <div className="card2-text-tag">
                    LOYOLA - Co-Curricular
                </div>

                <div className="card2-text-title" data-aos="fade-up" delay="200">
                    <h1 className='black'>CoCurricular</h1>
                    <h1 className='ok'>Unlock new opportunities</h1>
                </div>

                <div className="card2-text-main" data-aos="fade-up" delay="200">
                Loyola High School believes that Co-Curricular Activities (formerly known as Extra Curricular Activities) are an essential means to instill active social interaction and holistic education in a student.
                </div>

                <div className="card2-text-nav">
                <i className="fa-solid fa-caret-right"></i><Link to="/cocurricular">Visit</Link>
                </div>

                <div className="card2-text-footer">
                    Co-curricullar Wins - Our Uniqueness! 
                </div>
            </div>
            </div>
            
            
        </div>
    </div>
    </>
  )
}

export default Home2
