import React, {useEffect} from 'react'
import './Contact.css'
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import the AOS styles
import { Helmet } from 'react-helmet';

const Contact = () => {

    const [result, setResult] = React.useState("");
    useEffect(() => {
        AOS.init({
          duration: 1400, // Animation duration in milliseconds
          once: true,     // Whether animation should happen only once
        });

        const textarea = document.getElementById('auto-resizing-textarea');

      textarea.addEventListener('input', function() {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 'px';
      });
      }, []);

      const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);
    
        formData.append("access_key", "3d23dc08-f2aa-4289-8f0d-c2f9882982dc");
    
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
          alert('Sent Successfully!')
        } else {
          console.log("Error", data);
          setResult(data.message);
        }
      };

  return (
    <>
    <Helmet>
      <title>Contact - Loyola</title>
      <meta name="Loyola - Contact Us for Info" description="Contact us, we will guide you through our formal path"/>
      <link rel="canonical" href="/contact" />
    </Helmet>
    <div className='connect' id='connect'>
        <div className="title">
            <div><hr />Contact Us<hr/></div>
        </div>
        <div className="connecting">
        <div className="contact-label" data-aos="fade-up">
            <div className="contact-label-title">Visit Us</div>
            <div className="contact-label-text">
                <div>Loyola High School and Junior College Pashan Road, Pune 411 008</div>
                <div>Office Hours: 9.30 a.m. to 11.00 a.m.</div>
                <div>Phone – High School : 020-25656699</div>
                <div>Phone – Primary School : 020-25667099</div>
                <div> Primary Email : <span className='contact-span'>primaryloyolapune@gmail.com</span></div>
                <div>Secondary Email: <span className='contact-span'>highschoolloyolapune@gmail.com</span></div>
            </div>
        </div>

        <div id='contact' className="contact" data-aos='zoom-in'>
    <section className='contact-container'>
        <form onSubmit={onSubmit} action="#" className='form'>
            <div className="input-box">
                <label htmlFor="">Name*</label>
                <input required type="text" placeholder='Enter your name'/>
            </div>
            <div className="input-box">
                <label htmlFor="">Email*</label>
                <input required type="text" placeholder='Name@example.com'/>
            </div>

            <div className="column">
            <div className="input-box">
                <label htmlFor="">Phone number*</label>
                <input type="text" placeholder='Enter your name'/>
            </div>
            </div>
            
            <div className="column">
            <div className="input-box">
                <label htmlFor="text">Organization*</label>
                <input required type="text" placeholder='Your organization'/>
            </div>

            <div className="input-box">
                <label htmlFor="text">Role*</label>
                <input required type="text" placeholder='Role'/>
            </div>
            </div>

            <div className="input-box">
                <label htmlFor="text">Tell us more*</label>
                <textarea id="auto-resizing-textarea"></textarea>
            </div>

            <button>Submit</button>
        </form>
    </section>
    </div>
    </div>
    </div>
    </>
  )
}

export default Contact
