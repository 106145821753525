import React from 'react';
import './LandingPage.css'; // Ensure you have this CSS file for styling
import Sidebar from './Sidebar';
import Home from './Home';
import Navbar from './Navbar';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Navbar/>
      <Home />
    </div>
  );
};

export default LandingPage;
